import React from "react";
import { useState } from "react";
import AuxSidebar from "./AuxSidebar";
import SidebarItem from "./SidebarItem";

import aberturasSrc from "../../assets/Aberturas.png";
import equipamientoSrc from "../../assets/Equipamiento.png";
import terminacionesSrc from "../../assets/Terminaciones.png";

export type Category = "aberturas" | "equipamiento" | "terminaciones";
interface SidebarItems {
  category: Category;
  src: string;
  id: number;
}

const sidebarItems: SidebarItems[] = [
  { category: "aberturas", src: aberturasSrc, id: 1 },
  { category: "equipamiento", src: equipamientoSrc, id: 2 },
  { category: "terminaciones", src: terminacionesSrc, id: 3 },
];

interface SidebarProps {
  showSidebar: boolean;
  setShowSidebar: (value: boolean) => void;
}

const Sidebar = ({ showSidebar, setShowSidebar }: SidebarProps) => {
  const [category, setCategory] = useState<Category | undefined>(undefined);

  const selectCategory = (category: Category) => {
    setCategory(category);
    setShowSidebar(true);
  };

  const getActiveId = () =>
    !category
      ? undefined
      : sidebarItems.find((item) => item.category === category)?.id;

  return (
    <div className="flex flex-row">
      <div className="w-32 bg-white h-full flex flex-col items-center justify-center z-10">
        <div className="h-fit bg-neutral-100 w-full">
          <div
            id="placeholder-top"
            className={`flex w-full h-16 bg-white ${
              getActiveId() === 1 && "rounded-br-2xl"
            }`}
          />
          {sidebarItems.map((item) => (
            <SidebarItem
              key={item.id}
              {...item}
              activeId={getActiveId()}
              onClick={() => selectCategory(item.category)}
            />
          ))}
          <div
            id="placeholder-bottom"
            className={`flex w-full h-16 bg-white ${
              getActiveId() === 3 && "rounded-tr-2xl"
            }`}
          />
        </div>
      </div>
      <AuxSidebar
        showSidebar={showSidebar}
        close={() => {
          setShowSidebar(false);
          setTimeout(() => {
            setCategory(undefined);
          }, 110);
        }}
        category={category}
      />
    </div>
  );
};

export default Sidebar;
