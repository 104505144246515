import React, { useState } from "react";
import Sidebar from "./Sidebar/Sidebar";
import MapActions from "./MapActions/MapActions";

const Content = () => {
  const [showSidebar, setShowSidebar] = useState(false);

  return (
    <div className="flex flex-row justify-between w-full h-[calc(100%_-_6rem)]">
      <Sidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      <MapActions showSidebar={showSidebar} />
    </div>
  )
};

export default Content;