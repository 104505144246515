import React from "react";

interface SidebarItemProps {
  id: number;
  category: string;
  src: string;
  activeId?: number;
  onClick: () => void;
}

const SidebarItem = ({
  id,
  category,
  src,
  activeId,
  onClick,
}: SidebarItemProps) => {
  const isActive = activeId === id;
  const isTopRounded = activeId && activeId === id - 1;
  const isBottomRounded = activeId && activeId === id + 1;
  return (
    <div
      className={`flex flex-col bg-white justify-center items-center py-2 cursor-pointer w-full ${
        isActive && "bg-neutral-100"
      } ${isBottomRounded && "rounded-br-2xl"} ${
        isTopRounded && "rounded-tr-2xl"
      }`}
      onClick={onClick}
    >
      <img className="w-8 h-8 pb-1" src={src} alt={category} />
      <span className="text-xs capitalize">{category}</span>
    </div>
  );
};

export default SidebarItem;
