import React from "react";
import { Item } from "./types";

interface GridItemsProps {
  items: Item[];
}

const GridItems = ({ items }: GridItemsProps) => {
  return (
    <div className="grid grid-cols-3 gap-4">
      {items.map((item) => (
        <div
          key={item.name}
          className="flex justify-center flex-col items-center"
        >
          <img className="w-20 h-20" src={item.img} alt={item.name} />
          <span className="text-sm text-gray-600 mt-1 whitespace-nowrap">
            {item.name}
          </span>
        </div>
      ))}
    </div>
  );
};

export default GridItems;
