import React from "react";
import logoSrc from "../assets/logo.png";

const Header = () => {
  return (
    <div className="h-24 bg-zinc-900 flex justify-between w-full px-24 items-center">
      <img className="w-40" src={logoSrc} />
      <select className="rounded-md h-11 text-center text-gray-600">
        <option>Guardar y salir</option>
        <option>Salir sin guardar</option>
        <option>Guardar y continuar</option>
      </select>
    </div>
  );
};

export default Header;
