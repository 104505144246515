import React from "react";
import Button from "../Button/Button";

import plus from "../../assets/+.png";
import minus from "../../assets/-.png";
import arrow from "../../assets/Flecha.png";

interface MapActionsProps {
  showSidebar: boolean;
}

const Zoom = () => (
  <div className="bg-white p-1 rounded-md h-14 justify-between flex flex-col mr-4">
    <button className="active:scale-90" onClick={() => console.log("Zoom in")}>
      <img src={plus} className="w-5" />
    </button>
    <button className="active:scale-90" onClick={() => console.log("Zoom out")}>
      <img src={minus} className="w-5" />
    </button>
  </div>
);

const MoveMap = () => (
  <div className="flex flex-col w-14 h-15 bg-white rounded-md p-1">
    <button
      className="flex justify-center active:scale-90"
      onClick={() => console.log("Move up")}
    >
      <img src={arrow} className="w-4" />
    </button>
    <div className="flex justify-around flex-row">
      <button
        className="active:scale-90"
        onClick={() => console.log("Move left")}
      >
        <img src={arrow} className="w-4 -rotate-90" />
      </button>
      <button
        className="active:scale-90"
        onClick={() => console.log("Move right")}
      >
        <img src={arrow} className="w-4 rotate-90" />
      </button>
    </div>
    <button
      className="flex justify-center active:scale-90"
      onClick={() => console.log("Move down")}
    >
      <img src={arrow} className="w-4 rotate-180" />
    </button>
  </div>
);

const MapActions = ({ showSidebar }: MapActionsProps) => {
  return (
    <div className="h-full flex justify-between flex-col py-7 pr-7">
      <div className="flex">
        {showSidebar && (
          <>
            <Button text="Fijar" onClick={() => console.log("Fijar")} />
            <Button text="Borrar" onClick={() => console.log("Borrar")} />
          </>
        )}
      </div>
      <div className="flex flex-row w-24 justify-between self-end">
        <Zoom />
        <MoveMap />
      </div>
    </div>
  );
};

export default MapActions;
