import React from "react";
import arrowSrc from "../../assets/activo.png";

interface ButtonProps {
  text: string;
  onClick: () => void;
  className?: string;
  hasArrow?: boolean;
}

const Button = ({ text, onClick, className, hasArrow }: ButtonProps) => {
  return (
    <button
      className={`bg-white flex justify-between items-center rounded-md px-7 py-3 m-2 text-gray-600 shadow-md hover:shadow-none active:scale-95 ${className}`}
      onClick={onClick}
    >
      <span>{text}</span>
      {hasArrow && (
        <img src={arrowSrc} className="w-3 rotate-180" alt="arrow" />
      )}
    </button>
  );
};

export default Button;
