import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { getProducts } from "./service";
import { Category } from "./Sidebar";
import { Product } from "./types";
import Navigation from "./Navigation";
import Button from "../Button/Button";
import arrowSrc from "../../assets/activo.png";
import "./styles.css";
import GridItems from "./GridItems";

interface AuxSidebarProps {
  showSidebar: boolean;
  close: () => void;
  category?: Category;
}

const AuxSidebar = ({ showSidebar, close, category }: AuxSidebarProps) => {
  const [products, setProducts] = useState<Product[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [productSelected, setProductSelected] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    const loadProducts = async () => {
      if (showSidebar && category) {
        try {
          setIsLoading(true);
          setError(false);
          setProductSelected(undefined);
          const products = await getProducts(category);
          setProducts(products);
        } catch (e) {
          setError(true);
        }
        setIsLoading(false);
      }
    };

    loadProducts();
  }, [showSidebar, category]);

  const loadContent = () => {
    if (isLoading || !products) {
      return "Loading...";
    }
    if (error) {
      return "Error";
    }

    if (!productSelected) {
      return products?.map((product) => (
        <Button
          className="py-2 px-4 w-full text-left mb-1 text-sm shadow-none"
          onClick={() => setProductSelected(product.name)}
          text={product.name}
          key={product.name}
          hasArrow
        />
      ));
    } else {
      const product = products?.find(
        (product) => product.name === productSelected
      );
      if (!product) return;
      return <GridItems items={product.items} />;
    }
  };

  const onClose = () => {
    setProductSelected(undefined);
    close();
  };

  const content = useMemo(loadContent, [
    isLoading,
    products,
    error,
    productSelected,
  ]);

  return (
    <div
      className={`flex flex-row ease-in-out duration-300 ${
        !showSidebar ? "-translate-x-96" : "translate-x-0"
      }`}
    >
      <div className="w-80 bg-neutral-100 h-full flex flex-col items-center p-4">
        {productSelected && category && (
          <Navigation
            category={category}
            goBack={() => setProductSelected(undefined)}
          />
        )}
        <span className="text-lg text-gray-600 font-bold self-start capitalize mb-2 tracking-tighter">
          {productSelected || category}
        </span>
        {content}
      </div>
      <div
        className="trapezoid bg-neutral-100 w-11 h-20 flex self-center items-center justify-center border-l border-neutral-100 cursor-pointer"
        onClick={onClose}
      >
        <img src={arrowSrc} alt="arrow" className="w-4 relative -left-3" />
      </div>
    </div>
  );
};

export default AuxSidebar;
